export enum Icons {
  Lock = 'lock',
  Location = 'location',
  Unlock = 'unlock',
  Pause = 'pause',
  Fault = 'fault',
  Global = 'global',
  Phone = 'phone',
  ArrowDown = 'arrow_down',
  Schiphol = 'schiphol',
  Maxem = 'maxem',
  Loading = 'loading',
  Checkmark = 'check-mark',
  Error = 'error',
  Power = 'power',
  Email = 'email',
  Alert = 'alert',
  QuestionMark = 'question-mark',
  Refresh = 'refresh',
  Uncertain = 'uncertain',
  RadioChecked = 'radio-checked',
  RadioUnchecked = 'radio-unchecked',
  NotFound = 'not-found'
}

export type Props = {
  name: Icons;
};
