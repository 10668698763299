import type { FC } from 'react';
import React from 'react';

import type { Props } from './types';

import styles from './TextBlock.module.scss';

/**
 * Renders a text block with a title (bold) and paragraph.
 */
const TextBlock: FC<Props> = ({ paragraph, title }) => (
  <div className={styles.container}>
    <p className={styles.title}>{title}</p>
    <p className={styles.paragraph}>{paragraph}</p>
  </div>
);

export default TextBlock;
