import type { FC, LazyExoticComponent } from 'react';
import React, { Suspense, lazy } from 'react';

import { Routes, Route, useSearchParams, Navigate } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import type { Nullable } from '@energy_cloud/ts-utils';

import type { Data, Props } from './types';
import { ServerStatus } from './types';

import Loader from './components/Loader/Loader';

import './App.css';

import type { Props as NotFoundProps } from './screens/NotFound/types';

const PaymentFailed: LazyExoticComponent<FC<Props>> = lazy(async () => import('./screens/PaymentFailed'));
const Home: LazyExoticComponent<FC<Props>> = lazy(async () => import('./screens/Home'));
const PaymentSuccess: LazyExoticComponent<FC<Props>> = lazy(async () => import('./screens/PaymentSuccess'));
const ConfirmSocket: LazyExoticComponent<FC<Props>> = lazy(async () => import('./screens/ConfirmSocket'));
const NotFound: LazyExoticComponent<FC<NotFoundProps>> = lazy(async () => import('./screens/NotFound'));

/**
 * Root component of the default flow. When having API data this will render.
 * Data from API will be injected into the root props.
 */
export const App: FC<Props> = ({ data }) => {
  const [urlParams] = useSearchParams();
  const status = urlParams.get('status');

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/not-found" element={<NotFound data={data} />} />
          <Route
            path="/:assetId"
            element={
              data.status !== ServerStatus.OK ? (
                // Check the server status. If it doesn't match "OK" navigate to "/not-found".
                <Navigate replace to="/not-found" />
              ) : (
                <HomeWrapper statusParam={status} data={data} />
              )
            }
          />
          <Route path="*" element={<NotFound data={{ ...data, status: ServerStatus.PageNotFound }} />} />
        </Routes>
      </Suspense>
    </QueryParamProvider>
  );
};

/**
 * Wrapper component for Home to render the correct screen based on the status search param.
 *
 * @param root0
 * @param root0.statusParam The current status search param.
 * @param root0.data The data props from the server to be propagated.
 */
const HomeWrapper: FC<{ statusParam: Nullable<string>; data: Data }> = ({ statusParam, data }): JSX.Element => {
  switch (statusParam) {
    case 'payment_success':
      return <PaymentSuccess data={data} />;
    case 'payment_failed':
      return <PaymentFailed data={data} />;
    case 'confirm_socket':
      return <ConfirmSocket data={data} />;
    default:
      return <Home data={data} />;
  }
};
