import type { FC, SVGProps } from 'react';
import React from 'react';

import type { Props } from './types';
import { Icons } from './types';

import { ReactComponent as Alert } from '../../assets/svg/alert.svg';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrow_down.svg';
import { ReactComponent as Checkmark } from '../../assets/svg/check-mark.svg';
import { ReactComponent as Email } from '../../assets/svg/email.svg';
import { ReactComponent as Error } from '../../assets/svg/error.svg';
import { ReactComponent as Fault } from '../../assets/svg/fault.svg';
import { ReactComponent as Global } from '../../assets/svg/global.svg';
import { ReactComponent as Loading } from '../../assets/svg/loading.svg';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Lock } from '../../assets/svg/lock.svg';
import { ReactComponent as Maxem } from '../../assets/svg/maxem.svg';
import { ReactComponent as NotFound } from '../../assets/svg/not-found.svg';
import { ReactComponent as Pause } from '../../assets/svg/pause.svg';
import { ReactComponent as Phone } from '../../assets/svg/phone.svg';
import { ReactComponent as Power } from '../../assets/svg/power.svg';
import { ReactComponent as QuestionMark } from '../../assets/svg/question-mark.svg';
import { ReactComponent as RadioChecked } from '../../assets/svg/radio-checked.svg';
import { ReactComponent as RadioUnchecked } from '../../assets/svg/radio-unchecked.svg';
import { ReactComponent as Refresh } from '../../assets/svg/refresh.svg';
import { ReactComponent as Schiphol } from '../../assets/svg/schiphol.svg';
import { ReactComponent as Uncertain } from '../../assets/svg/uncertain.svg';
import { ReactComponent as Unlock } from '../../assets/svg/unlock.svg';

import styles from './Icon.module.scss';

const ICONS: {
  [key in Icons]: FC<SVGProps<SVGSVGElement>> & { title?: string };
} = {
  [Icons.Lock]: Lock,
  [Icons.Location]: Location,
  [Icons.Unlock]: Unlock,
  [Icons.Pause]: Pause,
  [Icons.Fault]: Fault,
  [Icons.Global]: Global,
  [Icons.Phone]: Phone,
  [Icons.ArrowDown]: ArrowDown,
  [Icons.Schiphol]: Schiphol,
  [Icons.Maxem]: Maxem,
  [Icons.Loading]: Loading,
  [Icons.Checkmark]: Checkmark,
  [Icons.Error]: Error,
  [Icons.Power]: Power,
  [Icons.Email]: Email,
  [Icons.Alert]: Alert,
  [Icons.QuestionMark]: QuestionMark,
  [Icons.Refresh]: Refresh,
  [Icons.Uncertain]: Uncertain,
  [Icons.RadioChecked]: RadioChecked,
  [Icons.RadioUnchecked]: RadioUnchecked,
  [Icons.NotFound]: NotFound
};

/**
 * Generic Icon component.
 */
const Icon: FC<Props> = ({ name }) => {
  const RequestedIconComponent = ICONS[name];
  return <RequestedIconComponent className={styles.container} />;
};

export default Icon;
