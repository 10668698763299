/**
 * For localization the library i18next is used.
 * This file specifies the configuration.
 * For more info, visit: https://www.i18next.com/.
 */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

import en from './en.json';
import nl from './nl.json';

export const resources = {
  en,
  nl
};

export const defaultNS = 'footer';

// eslint-disable-next-line import/no-named-as-default-member,@typescript-eslint/no-floating-promises
i18n
  // Detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector,
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options.
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources,
    defaultNS
  });

export default i18n;
