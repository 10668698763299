export type LocationDetails = {
  name: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
};

export type Status =
  | 'Available'
  | 'Charging'
  | 'Preparing'
  | 'EVdetected'
  | 'Finishing'
  | 'Suspendedevse'
  | 'Suspendedev'
  | 'Occupied'
  | 'Reserved'
  | 'Unavailable'
  | 'Faulted'
  | 'Offline'
  | 'Unknown';

export enum ServerStatus {
  AssetNotFound = 'ASSET_NOT_FOUND',
  PageNotFound = 'PAGE_NOT_FOUND',
  OK = 'OK'
}

export type Data = {
  /**
   * The url of the organisation logo.
   */
  organizationLogoUrl: string;
  /**
   * The serial of the asset.
   */
  serial: string;
  /**
   * Some information about the location where the asset is located.
   */
  location: LocationDetails;
  /**
   * Asset information, including its sockets.
   */
  asset: {
    /**
     * Per socket a status and the ID.
     */
    sockets: Array<{
      id: number;
      label: string;
      status: Status;
    }>;
    /**
     * Physical limit of the asset in kW.
     */
    limit: number;
  };
  /**
   * Some support contact information, in case the EV driver want to have some help.
   */
  contact: {
    phone: string;
    website: string;
    organizationName: string;
  };
  /**
   * The currency of the tariffs to display.
   * Value will be propagated to js.toLocaleString, so specify the { currency } value.
   */
  currency: string;
  /**
   * The amount of VAT included in tariff rates. Given as a ratio.
   */
  vat: number;
  /**
   * Tariff rates per category.
   */
  tariffs: {
    kwh: number;
    hour: number;
    session: number;
  };
  status: ServerStatus;
  /**
   * Boolean to indicate if an asset has the online-pay subscription. This is used to hide certain content regarding online pay.
   */
  hasOnlinePay: boolean;
  resellerDetails: {
    name?: string;
    logoUrl?: string;
    phoneNumber?: string;
    website?: string;
  };
};

export type Props = {
  data: Data;
};
