import type { FC } from 'react';
import React from 'react';

import { useTranslation } from 'react-i18next';

import Icon from '../Icon/Icon';

import { Icons } from '../Icon/types';

import type { Props } from './types';

import styles from './Footer.module.scss';

/**
 * Footer of the page.
 */
const Footer: FC<Props> = ({ phone, website, serial, organizationName, resellerLogoUrl }) => {
  const { t: translate } = useTranslation('footer');

  // TODO 23-08-2023 S.Dejong: I wish nested ternaries were socially acceptable
  let trimmedWebsite = '';
  if (website.startsWith('https://')) trimmedWebsite = website.substring('https://'.length);
  else if (website.startsWith('http://')) trimmedWebsite = website.substring('http://'.length);
  else trimmedWebsite = website;

  return (
    <div className={styles.container}>
      <span className={styles.title}>{translate('service')}</span>

      {phone && (
        <div className={styles.row}>
          <div className={styles.icon}>
            <Icon name={Icons.Phone} />
          </div>
          <span>{phone}</span>
        </div>
      )}

      {trimmedWebsite && (
        <div className={styles.row}>
          <div className={styles.icon}>
            <Icon name={Icons.Global} />
          </div>
          <a className={styles.link} href={`https://${trimmedWebsite}`} target={'_blank'} rel="noreferrer">
            {trimmedWebsite}
          </a>
        </div>
      )}

      {serial && (
        <>
          <span className={styles.chargingStation}>{translate('chargingId')}</span>
          <span className={styles.chargingStationId}>{serial}</span>
        </>
      )}
      {organizationName && (
        <p className={styles.chargingStation}>{translate('notes', { company: organizationName })}</p>
      )}
      <div className={styles.logo}>
        {resellerLogoUrl ? (
          <img src={resellerLogoUrl} alt={''} className={styles.footerLogo} />
        ) : (
          <Icon name={Icons.Maxem} />
        )}
      </div>
    </div>
  );
};

export default Footer;
