import type { ReactNode } from 'react';
import React from 'react';

import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import devProps from './props.json';
import reportWebVitals from './reportWebVitals';
import NotFound from './screens/NotFound';

import { App as DefaultApp } from './App';
import { NoProps } from './screens/NotFound/types';

import './App.css';
import './assets/fonts/ProximaNova-Bold.otf';
import './assets/fonts/ProximaNova-Extrabold.otf';
import './assets/fonts/ProximaNova-Light.otf';
import './assets/fonts/ProximaNova-Regular.otf';
import './assets/fonts/ProximaNova-Semibold.otf';
import './index.css';
import './locales';

import type { Data } from './types';

// Get root element from index.html.
const rootElement = document.getElementById('root')!;

// Check if current path starts with /qr
const isWhiteLabel = window.location.pathname.startsWith('/qr');

/**
 * Determines whether to render the default app or some fallback UI. Usually when data could not be parsed to JSON from
 * the main index.html.
 */
const renderApp = (): ReactNode => {
  if (process.env.NODE_ENV === 'development') return <DefaultApp data={devProps as Data} />;

  try {
    const rootProps = JSON.parse(rootElement.getAttribute('data-props') ?? '{}') ?? {};

    return <DefaultApp data={rootProps} />;
  } catch (error) {
    // This is serious, so would be nice have this information in production as well.
    // eslint-disable-next-line no-console
    console.warn('Could not load data from server.');
  }

  return <NotFound data={NoProps.NO_PROPS} />;
};

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <BrowserRouter basename={isWhiteLabel ? '/qr' : ''}>{renderApp()}</BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
