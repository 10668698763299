import type { FC } from 'react';
import React from 'react';

import { Oval } from 'react-loader-spinner';

import styles from './Loader.module.scss';

const Loader: FC = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <Oval
        height={80}
        width={80}
        color="#36c3fe"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#36c3fe"
        strokeWidth={3}
        strokeWidthSecondary={3}
      />
    </div>
  </div>
);

export default Loader;
