import type { FC } from 'react';
import React from 'react';

import { useTranslation } from 'react-i18next';

import Car from '../../assets/images/car.png';
import Footer from '../../components/Footer/Footer';
import Icon from '../../components/Icon/Icon';
import TextBlock from '../../components/TextBlock/TextBlock';

import { Icons } from '../../components/Icon/types';
import { ServerStatus } from '../../types';

import type { Props } from './types';

import styles from './styles.module.scss';

const WEBSITE_URL = 'www.maxem.energy';

/**
 * This screen informs the user an asset/ page couldn't be found. Or informs that an error occurred.
 */
const NotFound: FC<Props> = ({ data }) => {
  const { t: translate } = useTranslation('notFound');

  let title = translate('somethingWrong');
  let paragraphTitle = translate('somethingWrongTitle');
  let paragraphText = translate('somethingWrongText');

  // When the server status is asset- or page not found. Render the not found text.
  if (
    typeof data === 'object' &&
    (data.status === ServerStatus.AssetNotFound || data.status === ServerStatus.PageNotFound)
  ) {
    title = translate('notFound');
    paragraphTitle = translate('noPageTitle');
    paragraphText = translate('noPageText');
  }

  return (
    <article className={styles.container}>
      <section className={styles.headerContainer}>
        <h1 className={styles.title}>{title}</h1>
        <article className={styles.imageContainer}>
          <img src={Car} alt="car" className={styles.image} />
          <span className={styles.statusIcon}>
            <Icon name={Icons.NotFound} />
          </span>
        </article>
      </section>
      <TextBlock title={paragraphTitle} paragraph={paragraphText} />
      <Footer website={WEBSITE_URL} />
    </article>
  );
};

export default NotFound;
